import React, { useState } from "react";
import { Box, useMediaQuery } from "@mui/material";
import { Outlet } from "react-router-dom";
import Navbar from "components/Navbar";
import Sidebar from "components/Sidebar";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useGetUserQuery } from 'state/api';

const Layout = () => {
  const isNonMobile = useMediaQuery("(min-width: 600px)");
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [isRightbarOpen, setIsRightbarOpen] = useState(true);
  const userId = useSelector((state) => state.global.userId);
  const activePage = useSelector((state) => state.global.activePage);
  const { data } = useGetUserQuery(userId);

  useEffect(() => {
    if (activePage === "geography") {
      setIsSidebarOpen(false)
      setIsRightbarOpen(false)
    }
  }, [activePage])

  return (
    <Box display={isNonMobile ? "flex" : "block"} width="100%" height="100%">
      <Sidebar
        user={data || {}}
        isNonMobile={isNonMobile}
        drawerWidth="250px"
        isSidebarOpen={isSidebarOpen}
        setIsSidebarOpen={setIsSidebarOpen}
      />

      <Box flexGrow={1} style={{ overflow: activePage === 'geography' && "hidden" }}>
        <Navbar
          user={data || {}}
          isSidebarOpen={isSidebarOpen}
          setIsSidebarOpen={setIsSidebarOpen}
          isRightbarOpen={isRightbarOpen}
          setIsRightbarOpen={setIsRightbarOpen}
        />
        <Outlet />
      </Box>
    </Box>
  );
};

export default Layout;