import React, { useEffect, useState } from "react";
import { Box, useTheme } from "@mui/material";
import { useGetCountyBusinessQuery,useGetCountyQuery } from "state/api";
import Header from "components/Header";
import CustomColumnMenu from "components/DataGridCustomColumnMenu";
import DataGridCustomToolbar from "components/DataGridCustomToolbar";
import { DataGrid } from "@mui/x-data-grid";
import { getCountyBusiness, getUsername, getCounty } from "helper/helper";
import jwt_decode from "jwt-decode";

const Customers = () => {
  const theme = useTheme();
  const [user, setUser] = useState()
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(20);
  const [sort, setSort] = useState({});
  const [search, setSearch] = useState("");
  // const [county,setCounty] = useState()
  const [businesses,setCountyBusinesses] = useState()

  const [searchInput, setSearchInput] = useState("");
  const token = localStorage.getItem("token");
  let decode = jwt_decode(token);
  const county = useGetCountyQuery(decode.county_id)
  const data = useGetCountyBusinessQuery({
    // page,
    // pageSize,
    // sort: JSON.stringify(sort),
    // search,
    county:county.isSuccess && county?.data[0].name,
  });

  console.log("DATA:",data)
  // setCountyBusinesses(data?.countyBusinesses)

  const flattenData = data.isSuccess && data.data.countyBusinesses.map(store => ({
    _id: store._id,
    ward: store.building.ward,
    business_name: store.business_name,
    branch_name: store.branch_name,
    business_category: store.business_category,
    business_email: store.business_email,
    business_phone: store.business_phone,
    payment_status: store.payment_status
  }))

  const columns = [
    {
      field: "business_name",
      headerName: "Business Name",
      flex: 0.5,
    },
    {
      field: "branch_name",
      headerName: "Branch",
      flex: 0.5,
    },
    {
      field: "ward",
      headerName: "Ward",
      flex: 0.5,
    },
    {
      field: "business_category",
      headerName: "Category",
      flex: 0.5,
    },
    {
      field: "business_email",
      headerName: "Email",
      flex: 0.5,
    },
    {
      field: "business_phone",
      headerName: "Phone Number",
      flex: 0.5,
    },

    {
      field: "payment_status",
      headerName: "Compliance",
      flex: 0.5,
    },
  ]
  // useEffect(() => {
  //   if (user) {
  //     const fetchCounty = async () => {
  //       try {
  //         const { data } = await getCounty(user.county_id)
  //         setCounty(data[0].name)
  //       } catch (error) {
  //         console.log("Error fetching county:", error);
  //       }
  //     }

  //     fetchCounty()
  //   }
  // }, [user])

  // useEffect(() => {
  //   const fetchUser = async () => {
  //     try {
  //       const res = await getUsername();
  //       setUser(res);
  //     } catch (error) {
  //       console.log("Error fetching user:", error);
  //     }
  //   };

  //   fetchUser();
  // }, []);

  // useEffect(() => {
  //   if(county){
  //     const fetchBusinesses = async () => {
  //       try {
  //         const {data} = await getCountyBusiness({county})
  //         setCountyBusinesses(data)
  //       } catch (error) {
  //         console.log("Error fetching buildings:", error);
  //       }
  //     }

  //     fetchBusinesses()
  //   }
  // }, [county])

  return (
    <Box m="1.5rem 2.5rem">
      <Header title="BUSINESSES" subtitle="List of Businesses" />
      <Box
        mt="40px"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: theme.palette.primary.light,
          },
          "& .MuiDataGrid-footerContainer": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderTop: "none",
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${theme.palette.secondary[200]} !important`,
          },
        }}
      >
        <DataGrid
          loading={data.isLoading || !data?.data.countyBusinesses}
          getRowId={(row) => row._id}
          rows={flattenData || []}
          columns={columns}
          rowCount={(flattenData && data?.data.total) || 0}
          rowsPerPageOptions={[20, 50, 100]}
          pagination
          page={page}
          pageSize={pageSize}
          paginationMode="server"
          sortingMode="server"
          onPageChange={(newPage) => setPage(newPage)}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          onSortModelChange={(newSortModel) => setSort(...newSortModel)}
          components={{
            ColumnMenu: CustomColumnMenu,
            Toolbar: DataGridCustomToolbar
          }}
          componentsProps={{
            toolbar: { searchInput, setSearchInput, setSearch },
          }}
        />
      </Box>
    </Box>
  );
};

export default Customers;