import React, { useState } from "react";
import { Box, Modal, Tab, Tabs } from "@mui/material";
import { useDispatch } from "react-redux";
import { setRevenueOfficer } from "state";
import validator from "validator";
import Daily from "scenes/daily";
import Monthly from "scenes/monthly";
import { Close } from "@mui/icons-material";

const ViewOfficer = ({ isOpen, onClose }) => {
    const [value, setValue] = React.useState(0);
    
    const handleTabChange = (event, newValue) => {
        setValue(newValue);
    };

    function TabPanel(props) {
        const { children, value, index, ...other } = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`tabpanel-${index}`}
                aria-labelledby={`tab-${index}`}
                {...other}
            >
                {value === index && (
                    <Box sx={{ p: 3 }}>
                        {children}
                    </Box>
                )}
            </div>
        );
    }

    return (
        <Modal open={isOpen} onClose={onClose}>
            <Box>
                <Box sx={{
                    display: "block",
                    position: "fixed",
                    height: 780,
                    width: 400, 
                    bgcolor: "background.paper", 
                    p: 2,
                    top: "0",
                    left: "0",
                    right: "0",
                    bottom: "0",
                    margin: "auto",
                    alignItems: "center",
                    justifyContent: "center",
                }}>
                    <Tabs value={value} onChange={handleTabChange} centered textColor="secondary"
                        indicatorColor="secondary">
                        {/* <Tab label="UPDATE" /> */}
                        <Tab label="DAILY" />
                        <Tab label="MONTHLY" />
                        <Tab icon={<Close />} onClick={()=>onClose()} />
                    </Tabs>

                    <TabPanel value={value} index={0}>
                        <Box sx={{
                            display: "block",
                            position: "fixed",
                            width: 800,
                            bgcolor: "background.paper",
                            p: 2,
                            top: "200",
                            left: "0",
                            right: "0",
                            bottom: "0",
                            margin: "auto",
                            alignItems: "center",
                            justifyContent: "center",
                        }}>
                            <Daily />
                        </Box>
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        <Box sx={{
                            display: "block",
                            position: "fixed",
                            width: 800,
                            bgcolor: "background.paper",
                            p: 2,
                            top: "200",
                            left: "0",
                            right: "0",
                            bottom: "0",
                            margin: "auto",
                            alignItems: "center",
                            justifyContent: "center",
                        }}>
                            <Monthly />
                        </Box>
                    </TabPanel>
                </Box>
            </Box>
        </Modal>
    );
}

export default ViewOfficer