import React, { useEffect, useState } from "react";
import {
  Box,
  Card,
  CardActions,
  CardContent,
  Collapse,
  Button,
  Typography,
  Rating,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import Header from "components/Header";
import { useGetProductsQuery } from "state/api";
import { getCountyWardSummaries } from "helper/helper";
import dayjs from "dayjs";

const Product = ({
  _id,
  month,
  month_balance,
  month_paid,
  paid_businesses,
  partially_paid_businesses,
  not_paid_businesses,
  quarter_balance,
  total_paid,
  ward,
  year_balance,
  year_paid
}) => {
  const theme = useTheme();
  const [isExpanded, setIsExpanded] = useState(false);

  function calculateRating(amountPaid, balance) {
    const ratio = parseFloat(amountPaid) / (parseFloat(amountPaid) + parseFloat(balance));
    console.log(amountPaid,balance,ratio)
    
    if (ratio >= 0.9) {
      return 5;
    } else if (ratio >= 0.7) {
      return 4;
    } else if (ratio >= 0.5) {
      return 3;
    } else if (ratio >= 0.3) {
      return 2;
    } else if (ratio >= 0.1) {
      return 1;
    } else {
      return 0;
    }
  }
  

  return (
    <Card
      sx={{
        backgroundImage: "none",
        backgroundColor: theme.palette.background.alt,
        borderRadius: "0.55rem",
      }}
    >
      <CardContent>
        <Typography
          sx={{ fontSize: 16, fontWeight: 'bold' }}
          color={theme.palette.secondary[400]}
          gutterBottom
        >
          {ward}
        </Typography>
        <Typography variant="h5" component="div">
          {dayjs(month).format("MMM-YYYY")}
        </Typography>
        <Typography sx={{ mb: "1rem", fontSize: 14, fontWeight: 'bold' }} color={theme.palette.secondary[400]}>
          Collected: Ksh {Number(month_paid).toFixed(2)}
        </Typography>
        <Typography sx={{ mb: "1rem", fontSize: 14, fontWeight: 'bold' }} color={theme.palette.secondary[400]}>
          Balance: Ksh {Number(month_balance).toFixed(2)}
        </Typography>
        <Rating value={calculateRating(month_paid,month_balance)} readOnly />

      </CardContent>
      <CardActions>
        <Button
          variant="primary"
          size="small"
          onClick={() => setIsExpanded(!isExpanded)}
        >
          See More
        </Button>
      </CardActions>
      <Collapse
        in={isExpanded}
        timeout="auto"
        unmountOnExit
        sx={{
          color: theme.palette.neutral[300],
        }}
      >
        <CardContent>
          <Typography>id: {_id}</Typography>
          <Typography>PAID: {paid_businesses}</Typography>
          <Typography>
            NOT PAID: {not_paid_businesses}
          </Typography>
          <Typography>
            PARTIALLY PAID: {partially_paid_businesses}
          </Typography>
        </CardContent>
      </Collapse>
    </Card>
  );
};

const Products = () => {
  const { data, isLoading } = useGetProductsQuery();
  const isNonMobile = useMediaQuery("(min-width: 1000px)");
  const [summaries,setSummaries] = useState()

  const fetchSummaries = () => {
    getCountyWardSummaries().then(({data}) => {
      console.log(data)
      setSummaries(data)
    })
  }

  useEffect(()=>{
    fetchSummaries()
  },[])

  return (
    <Box m="1.5rem 2.5rem">
      <Header title="WARDS" subtitle="See all ward summaries." />
      {summaries !== undefined? (
        <Box
          mt="20px"
          display="grid"
          gridTemplateColumns="repeat(4, minmax(0, 1fr))"
          justifyContent="space-between"
          rowGap="20px"
          columnGap="1.33%"
          sx={{
            "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
          }}
        >
          {summaries.map(
            ({
              _id,
              month,
              month_balance,
              month_paid,
              paid_businesses,
              partially_paid_businesses,
              not_paid_businesses,
              quarter_balance,
              total_paid,
              ward,
              year_balance,
              year_paid,              
            }) => (
              <Product
                key={_id}
                _id={_id}
                month={month}
                month_balance={month_balance}
                month_paid={month_paid}
                not_paid_businesses={not_paid_businesses}
                paid_businesses={paid_businesses}
                partially_paid_businesses={partially_paid_businesses}
                quarter_balance={quarter_balance}
                total_paid={total_paid}
                ward={ward.name}
                year_balance={year_balance}
                year_paid={year_paid}
              />
            )
          )}
        </Box>
      ) : (
        <>Loading...</>
      )}
    </Box>
  );
};

export default Products;