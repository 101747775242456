import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setActivePage } from "state";
import MapView from "components/Map";

const Geography = () => {
  const dispatch = useDispatch()
  const [isRightbarOpen, setIsRightbarOpen] = useState(true);

  useEffect(() => {
    dispatch(setActivePage("geography"))
  }, []);

  return (
    <React.Fragment>
      <MapView isRightbarOpen={isRightbarOpen} setIsRightbarOpen={setIsRightbarOpen} />
    </React.Fragment>
  );
};

export default Geography;