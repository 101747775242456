import Header from "components/Header";
import * as React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Divider, Typography, useTheme } from "@mui/material";
import Paper from "@mui/material/Paper";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Button from "@mui/material/Button";
import FlexBetween from "components/FlexBetween";
import { AddBusinessOutlined, AddCircleOutline } from "@mui/icons-material";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

function createData(name, subcategory) {
  return {
    name,
    subcategory: [...subcategory],
  };
}

function Row(props) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);

  return (
    <React.Fragment>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell component="th" scope="row" align="left">
          {row.name}
        </TableCell>
        <TableCell align="right">
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>Sub Category</TableCell>
                    <TableCell>Amount</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.subcategory.map((subcategoryRow) => (
                    <TableRow key={subcategoryRow.date}>
                      <TableCell>{subcategoryRow.name}</TableCell>
                      <TableCell>{subcategoryRow.amount}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

Row.propTypes = {
  row: PropTypes.shape({
    calories: PropTypes.number.isRequired,
    carbs: PropTypes.number.isRequired,
    fat: PropTypes.number.isRequired,
    subcategory: PropTypes.arrayOf(
      PropTypes.shape({
        amount: PropTypes.number.isRequired,
        customerId: PropTypes.string.isRequired,
        date: PropTypes.string.isRequired,
      })
    ).isRequired,
    name: PropTypes.string.isRequired,
    price: PropTypes.number.isRequired,
    protein: PropTypes.number.isRequired,
  }).isRequired,
};

const rows = [
  createData("Healthcare Facilities", [
    {
      name: "Assisted Living Facilities",
      amount: 300,
    },
    {
      name: "Nursing Homes",
      amount: 250,
    },
  ]),
  createData("Non-Profit Organizations", [
    {
      name: "Charitable Organizations",
      amount: 200,
    },
    {
      name: "Religious Institutions",
      amount: 100,
    },
  ]),
  createData("Recreation & Leisure", [
    {
      name: "Golf Clubs",
      amount: 200,
    },
    {
      name: "Country Clubs",
      amount: 100,
    },
  ]),
  createData("Industrial Complexes", [
    {
      name: "Manufacturing Facilities",
      amount: 20000,
    },
    {
      name: "Distribution Centers",
      amount: 1000,
    },
  ]),
];

export default function Levy() {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [subCategories, setSubCategories] = React.useState([""]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleAddSubCategory = () => {
    setSubCategories([...subCategories, ""]); // Add an empty sub-category to the array
  };

  const handleSubCategoryChange = (index, value) => {
    const updatedSubCategories = [...subCategories];
    updatedSubCategories[index] = value;
    setSubCategories(updatedSubCategories);
  };

  return (
    <Box m="1.5rem 2.5rem">
      <FlexBetween>
        <Header
          title="CATEGORIES"
          subtitle="Levy categories and sub-categories"
        />

        <Box>
          <Button
            sx={{
              backgroundColor: theme.palette.secondary.light,
              color: theme.palette.background.alt,
              fontSize: "14px",
              fontWeight: "bold",
              padding: "10px 20px",
            }}
            onClick={handleClickOpen}
          >
            <AddBusinessOutlined sx={{ mr: "10px" }} />
            Create Category
          </Button>
        </Box>
      </FlexBetween>
      <Box mt="40px" height="75vh">
        <TableContainer component={Paper}>
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow>
                <TableCell>Levy Category Name</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <Row key={row.name} row={row} />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Create a Category and It's Sub-categories</DialogTitle>
        <DialogContent>
          <DialogContentText>
            To create a new category, please enter a category name and it's associated
            subcategories and levy amounts here.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="category"
            label="Category"
            type="text"
            fullWidth
            variant="standard"
          />
          {subCategories.map((subCategory, index) => (
            <Box sx={{ display: "flex" }} key={index}>
              <TextField
                autoFocus
                margin="dense"
                id={`sub_category_${index}`}
                label="Sub-Category"
                type="text"
                fullWidth
                variant="standard"
                value={subCategory}
                onChange={(e) => handleSubCategoryChange(index, e.target.value)}
              />
              <br/>
              <Typography sx={{ marginBottom: '1rem' }}>{' '}<br/></Typography>
              <TextField
                autoFocus
                margin="dense"
                id={`amount_${index}`}
                label="Levy"
                type="text"
                fullWidth
                variant="standard"
                value={subCategory}
                onChange={(e) => handleSubCategoryChange(index, e.target.value)}
              />
              {index === subCategories.length - 1 && (
                <IconButton
                  aria-label="add sub-category"
                  onClick={handleAddSubCategory}
                >
                  <AddCircleOutline />
                </IconButton>
              )}
            </Box>
          ))}
        </DialogContent>
        <DialogActions>
          <Button
            sx={{
              color: theme.palette.secondary.light,
            }}
            variant="text"
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            sx={{
              backgroundColor: theme.palette.secondary.light,
              color: theme.palette.background.alt,
            }}
            onClick={handleClose}
          >
            Create
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
