import React, { useEffect, useState, useRef } from 'react';
import Map, { Marker, Popup, NavigationControl, Source, Layer } from 'react-map-gl';
import { interpolateOranges, interpolateGreens, interpolateReds, interpolateYlOrRd } from 'd3-scale-chromatic';
import { useSelector } from 'react-redux';
import Dot from './Dot';
import PopupDetails from './PopupDetails';
import { getAllBuildingStores, calculateMapCenter, clusterCoordinates } from 'helper/helper';
import { useGetCountyBuildingsQuery } from 'state/api';

const MapView = () => {
    const [userLocation, setUserLocation] = useState(null);
    const mapType = useSelector(state => state.global.mapType)
    const mapStyle = useSelector(state => state.global.mapStyle)
    const cty = useSelector(state => state.global.county)
    const county = Object.keys(cty).length === 0 ? JSON.parse(localStorage.getItem("county")) : cty
    console.log(county)
    const MapMarker = 'data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23ffffff"%3E%3Cpath d="M12 2C8.13 2 5 5.13 5 9c0 6 7 13 7 13s7-7 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z" /%3E%3C/svg%3E'
    const [selectedMarker, setSelectedMarker] = useState();
    const [zoom, setZoom] = useState(1);
    const [filteredbuildings, setFilteredBuildings] = useState()
    const MAPBOX_TOKEN = process.env.REACT_APP_MAPBOX_TOKEN
    const mapRef = useRef();
    const [bounds, setBounds] = useState()
    const [threshold, setThreshold] = useState()
    const countybuildings = useGetCountyBuildingsQuery({ county:county?.name, category: "" })
    countybuildings.isSuccess && localStorage.setItem("countybuildings",JSON.stringify(countybuildings?.data))
    const [viewport, setViewport] = useState();
    const mapKey = JSON.stringify(viewport);

    const dots = [
        { color: "green", label: "Paid", value: "Paid" },
        { color: "yellow", label: "Partially paid", value: "Partially paid" },
        { color: "red", label: "Not paid", value: "Not paid" },
    ];

    const getHeatmapColor = (value) => {
        const scale = interpolateOranges;
        return scale(value);
    };

    const paidColor = (value) => {
        const scale = interpolateGreens;
        return scale(value);
    };

    const notPaidColor = (value) => {
        const scale = interpolateReds;
        return scale(value);
    };

    const partiallyPaidColor = (value) => {
        const scale = interpolateYlOrRd;
        return scale(value);
    };

    const getUserLocation = () => {
        navigator.geolocation.getCurrentPosition(
            (position) => {
                const { latitude, longitude } = position.coords;
                setUserLocation({ latitude, longitude });
            },
            (error) => console.error(error),
            { enableHighAccuracy: true, timeout: 20000, maximumAge: 1000 }
        );
    };

    const handleSelected = async (marker, index) => {
        getAllBuildingStores({ _id: marker._id }).then(({ data }) => {
            setSelectedMarker(data)
        })
    };

    const handleClosePopup = () => {
        setSelectedMarker(null);
    };

    const paymentStatusColors = {
        "Paid": "green",
        "Partially Paid": "yellow",
        "Not Paid": "red",
        "No Occupants": "blue"
    };

    const heatmapData = {
        type: 'FeatureCollection',
        name: "buildings",
        features: filteredbuildings?.map((building) => ({
            type: 'Feature',
            _id: building._id,
            longitude: building.longitude,
            latitude: building.latitude,
            payment_status: building.payment_status,

            geometry: {
                type: 'Point',
                coordinates: [parseFloat(building.longitude), parseFloat(building.latitude)]
            },
        }))
    };

    const heatmapLayer = {
        id: 'heatmap-layer',
        type: 'heatmap',
        source: {
            type: 'geojson',
            data: heatmapData
        },
        paint: {
            'heatmap-opacity': 0.9,
            'heatmap-radius': 25,
            'heatmap-weight': 2,
            'heatmap-color': [
                'interpolate',
                ['linear'],
                ['heatmap-density'],
                0, 'rgba(0, 0, 255, 0)',
                0.4, notPaidColor(0.4),
                0.6, partiallyPaidColor(0.6),
                0.8, paidColor(0.8),
                1, getHeatmapColor(1)
            ]
        }
    };

    useEffect(() => {
        const filteredMarkers = countybuildings.isSuccess && countybuildings.data.filter(marker => {
            const { longitude, latitude } = marker;
            const lng = parseFloat(longitude);
            const lat = parseFloat(latitude);
            return lng >= bounds?._sw.lng && lng <= bounds?._ne.lng && lat >= bounds?._sw.lat && lat <= bounds?._ne.lat;
        });

        const clusteredCoordinates = clusterCoordinates(filteredMarkers, threshold);
        let clustered = []
        for (let i of clusteredCoordinates) {
            let output = {
                ...i.center,
                coordinates: i.coordinates
            }
            clustered.push(output)
        }

        setFilteredBuildings(clustered)
    }, [bounds, mapRef, threshold])

    useEffect(() => {
        const filteredMarkers = countybuildings.isSuccess && countybuildings.data.filter(marker => {
            const { longitude, latitude } = marker;
            const lng = parseFloat(longitude);
            const lat = parseFloat(latitude);
            return lng >= bounds?._sw.lng && lng <= bounds?._ne.lng && lat >= bounds?._sw.lat && lat <= bounds?._ne.lat;
        });

        const clusteredCoordinates = clusterCoordinates(filteredMarkers, threshold);
        let clustered = []
        for (let i of clusteredCoordinates) {
            let output = {
                ...i.center,
                coordinates: i.coordinates
            }
            clustered.push(output)
        }

        countybuildings.isSuccess && setFilteredBuildings(filteredMarkers)
    }, [])

    useEffect(() => {
        console.log("countybuildings:", countybuildings)
        let temp = countybuildings.isSuccess && countybuildings?.data
        let lat = countybuildings.isSuccess && calculateMapCenter(temp).latitude
        let lng = countybuildings.isSuccess && calculateMapCenter(temp).longitude
        countybuildings.isSuccess && setViewport({
            latitude: lat,
            longitude: lng,
            zoom: 15
        })
    }, [mapRef,countybuildings])

    useEffect(() => {
        getUserLocation();
    }, []);

    useEffect(() => {
        setBounds(getBounds())
    }, [mapRef])

    useEffect(() => {
        switch (true) {
            case zoom >= 1 && zoom <= 10:
                setThreshold(0.1)
                break;
            case zoom >= 11 && zoom <= 13:
                setThreshold(0.01)
                break;
            case zoom >= 14 && zoom <= 16:
                setThreshold(0.001)
                break;
            case zoom === 17:
                setThreshold(0.0001)
                break;
            case zoom >= 18 && zoom <= 22:
                setThreshold(0.00001)
                break;
            default:
                break;
        }
    }, [zoom])

    const getBounds = () => {
        if (!mapRef.current) {
            return null;
        }
        return mapRef.current.getBounds();
    };

    return (
        <Map
            ref={mapRef}
            key={mapKey}
            initialViewState={{ ...viewport }}
            onDrag={(event) => {
                setZoom(event.viewState.zoom)
                setBounds(getBounds())
            }}
            style={{ width: '100vw', height: '100vh' }}
            mapStyle={mapStyle}
            mapboxAccessToken={MAPBOX_TOKEN}
            onZoom={(event) => {
                setZoom(event.viewState.zoom)
                setBounds(getBounds())
            }}
        >
            {userLocation && (
                <Marker latitude={userLocation?.latitude} longitude={userLocation?.longitude}>
                    <div>📍</div>
                </Marker>
            )}

            {Array.isArray(filteredbuildings) && mapType === "Markers" ? (filteredbuildings?.map((marker, index) =>
            (
                <Marker key={index} latitude={marker.latitude} longitude={marker.longitude} anchor="bottom">
                    <img
                        onClick={() => handleSelected(marker)}
                        style={{
                            height: "15px",
                            width: "14px",
                            cursor: "pointer",
                            backgroundColor: paymentStatusColors[marker.payment_status],
                            borderRadius: "50px"
                        }}
                        src={MapMarker} alt="mapmarker" />
                </Marker>
            ))) : Array.isArray(filteredbuildings) && mapType === "Clusters" ? (<Source type="geojson" data={{ type: 'FeatureCollection', features: heatmapData?.features }}>
                <Layer style={{ cursor: 'pointer' }}  {...heatmapLayer} onClick={(e) => {
                    const marker = e.features[0];
                    handleSelected(marker)
                }} interactive={true} />
            </Source>) : (<></>)
            }

            {selectedMarker && (
                <Popup
                    latitude={selectedMarker.latitude}
                    longitude={selectedMarker.longitude}
                    anchor="top-left"
                    onClose={handleClosePopup}
                    closeButton={true}
                    closeOnClick={false}
                    style={{ maxWidth: "700px", maxHeight: "500px", overflow: "auto", backgroundColor: "rgba(0, 188, 212, 0.2)" }}
                >
                    <PopupDetails selectedMarker={selectedMarker} />
                </Popup>
            )}
            <NavigationControl showCompass showZoom position='bottom-left' />
            {dots.map((dot, index) => (
                <Dot
                    key={index}
                    index={index}
                    color={dot.color}
                    label={dot.label}
                />
            ))}
        </Map>
    );
};

export default MapView;