import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const token = localStorage.getItem("token");
function addHeaders(token, endpoint) {
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  return headers;
}

export const api = createApi({
  baseQuery: fetchBaseQuery({ baseUrl: process.env.REACT_APP_BASE_URL }),
  reducerPath: "adminapi",
  tagTypes: [
    "User",
    "Products",
    "Customers",
    "Transactions",
    "Businesses",
    "Geography",
    "Sales",
    "Admins",
    "Performance",
    "Dashboard",
    "Roles",
    "County",
    "Escalations"
  ],
  endpoints: (build) => ({
    getUser: build.query({
      query: (id) => `general/user/${id}`,
      providesTags: ["User"],
    }),
    getProducts: build.query({
      query: () => `client/products`,
      providesTags: ["Products"],
    }),
    getCustomers: build.query({
      query: () => `client/customers`,
      providesTags: ["Customers"],
    }),
    getTransactions: build.query({
      query: ({ page, pageSize, sort, search }) => ({
        url: "client/transactions",
        method: "GET",
        params: { page, pageSize, sort, search },
      }),
      providesTags: ["Transactions"],
    }),
    getCountyBusiness: build.query({
      query: ({ page, pageSize, sort, search, county }) => ({
        url: `business/county-businesses/${county}`,
        method: "GET",
        params: { page, pageSize, sort, search },
        headers: addHeaders(localStorage.getItem("token"), `/business/county-businesses/${county}`)
      }),
      providesTags: ["SingleBusinessPermits"],
    }),
    getCountyBuildings: build.query({
      query: ({ county, category }) => ({
        url: `buildings/by-county/${county}`,
        method: "GET",
        headers: addHeaders(localStorage.getItem("token"), `buildings/by-county/${county}`),
        params: category !== "" ? { category } : {},
      }),
      providesTags: ["Buildings"],
    }),
    getGeography: build.query({
      query: () => "client/geography",
      providesTags: ["Geography"],
    }),
    getSales: build.query({
      query: () => "sales/sales",
      providesTags: ["Sales"],
    }),
    // getAdmins: build.query({
    //     query: () => "management/admins",
    //     providesTags: ["Admins"],
    // }),
    getCounty: build.query({
      query: (code) => ({
        url: `/county/counties/${code}`,
        method: "GET",
        headers: addHeaders(localStorage.getItem("token"), `/county/counties/${code}`),
      }),
      provideTags: ["County"]
    }),
    getAdmins: build.query({
      query: ({ page, pageSize, sort, search }) => ({
        url: "management/admins",
        method: "GET",
        params: { page, pageSize, sort, search },
      }),
      providesTags: ["Admins"],
    }),
    getUserPerformance: build.query({
      query: (id) => `management/performance/${id}`,
      providesTags: ["Performance"],
    }),
    getDashboard: build.query({
      // query: () => "general/dashboard",
      query: () => "general/summaries",
      providesTags: ["Dashboard"],
    }),
    /**SIGNUP */
    postUser: build.mutation({
      query: (signupData) => ({
        url: "",
        method: "POST",
        body: signupData,
      }),
      invalidatesTags: ["User"],
    }),
    /**ESCALATIONS */
    getEscalations: build.query({
      query: () => ({
        url: `/business/escalations`,
        method: "GET",
        headers: addHeaders(localStorage.getItem("token"), `/business/escalations`),
      }),
      providesTags: ["Escalations"]
    }),
  }),
});

export const {
  useGetUserQuery,
  useGetProductsQuery,
  useGetCustomersQuery,
  useGetTransactionsQuery,
  useGetGeographyQuery,
  useGetSalesQuery,
  useGetAdminsQuery,
  useGetUserPerformanceQuery,
  useGetDashboardQuery,
  useGetCountyBusinessQuery,
  useGetCountyBuildingsQuery,
  useGetCountyQuery
} = api;
