import axios from "axios";
import jwt_decode from "jwt-decode";

axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;

/** Make API Requests */

/** To get email from Token */
export async function getUsername() {
  const token = localStorage.getItem("token");
  if (!token) return Promise.reject("Cannot find Token");
  let decode = jwt_decode(token);
  return decode;
}

/** authenticate function */
export async function authenticate(email) {
  try {
    return await axios.post("/auth/authenticate", { email });
  } catch (error) {
    return { error: "Username doesn't exist...!" };
  }
}

/** get User details */
export async function getUser({ email }) {
  try {
    const { data } = await axios.get(`/auth/user/${email}`);
    return { data };
  } catch (error) {
    return { error: "Password doesn't Match...!" };
  }
}

/** get Users */
export async function getUsers() {
  try {
    const data = await axios.get("/auth/users");
    return data;
  } catch (error) {
    return { error: "Users not found" };
  }
}

/**get officers */
export async function getOfficers({ county, role }) {
  try {
    const data = await axios.get(`/auth/officers/${county}/${role}`);
    return data;
  } catch (error) {
    return { error: "Users not found" };
  }
}

/** register user function */
export async function registerUser(credentials) {
  try {
    const {
      data: { msg },
      status,
    } = await axios.post(`/auth/register`, credentials);

    let { email } = credentials;

    /** send email */
    if (status === 201) {
      await axios.post("/auth/registerMail", { userEmail: email, text: msg });
    }

    return Promise.resolve(msg);
  } catch (error) {
    return Promise.reject({ error });
  }
}

/** login function */
export async function verifyPassword({ email, password }) {
  try {
    if (email) {
      const { data } = await axios.post("/auth/login", { email, password });
      return Promise.resolve({ data });
    }
  } catch (error) {
    return Promise.reject({ error: "Password doesn't Match...!" });
  }
}

/** update user profile function */
export async function updateUser(response) {
  try {
    const token = await localStorage.getItem("token");
    const data = await axios.put("/auth/updateuser", response, {
      headers: { Authorization: `Bearer ${token}` },
    });

    return Promise.resolve({ data });
  } catch (error) {
    return Promise.reject({ error: "Couldn't Update Profile...!" });
  }
}

/** generate OTP */
export async function generateOTP(msisdn) {
  try {
    const {
      data: { code },
      status,
    } = await axios.post("/auth/generateOTP", { msisdn });

    // send mail with the OTP
    if (status === 201) {
      let {
        data: { msisdn },
      } = await getUser({ msisdn });
      let text = `Your Password Recovery OTP is ${code}. Verify and recover your password.`;
      await axios.post("/auth/registerMail", {
        userEmail: msisdn,
        text,
        subject: "Password Recovery OTP",
      });
    }
    return Promise.resolve(code);
  } catch (error) {
    return Promise.reject(`Bug: ${error}`);
  }
}

/** verify OTP */
export async function verifyOTP({ msisdn, code }) {
  try {
    const { data, status } = await axios.get("/auth/verifyOTP", {
      params: { msisdn, code },
    });
    return { data, status };
  } catch (error) {
    return Promise.reject(error);
  }
}

/** reset password */
export async function resetPassword({ email, password }) {
  try {
    const { data, status } = await axios.put("/auth/resetPassword", {
      email,
      password,
    });
    return Promise.resolve({ data, status });
  } catch (error) {
    return Promise.reject({ error });
  }
}

/** create building */
export async function createBuilding(body) {
  try {
    const token = await localStorage.getItem("token");
    const data = await axios.post(`/buildings/create`, body, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject({ error });
  }
}

/** get building */
export async function getBuildingById({ _id }) {
  try {
    const token = await localStorage.getItem("token");
    const { data } = await axios.get(`/buildings/building/${_id}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return { data };
  } catch (error) {
    return { error };
  }
}

/** get building */
export async function getAllBuildingStores({ _id }) {
  const token = await localStorage.getItem("token");
  try {
    const { data } = await axios.get(`/buildings/${_id}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return { data };
  } catch (error) {
    return { error };
  }
}

/** get buildings */
export async function getAllCountyBuildings(county) {
  try {
    const token = await localStorage.getItem("token");
    const data = await axios.get(`/buildings/buildings/${county}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return data;
  } catch (error) {
    return { error: "Buildings not found" };
  }
}

/** update building function */
export async function updateBuilding(building) {
  try {
    const token = await localStorage.getItem("token");
    const data = await axios.put("/building/update", building, {
      headers: { Authorization: `Bearer ${token}` },
    });

    return Promise.resolve({ data });
  } catch (error) {
    return Promise.reject({ error: "Couldn't Update Building...!" });
  }
}

/** create business */
export async function createBusiness(body) {
  try {
    const token = await localStorage.getItem("token");
    const data = await axios.post(`/business/register`, body, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject({ error });
  }
}

/** get business */
export async function getBusinessById({ business }) {
  try {
    const token = await localStorage.getItem("token");
    const { data } = await axios.get(`/business/business/${business}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return { data };
  } catch (error) {
    return { error };
  }
}

/** get business */
export async function getCountyBusiness({
  page,
  pageSize,
  sort,
  search,
  county,
}) {
  try {
    const token = await localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    const { data } = await axios.get(`/business/county-businesses/${county}`, {
      headers,
      params: { page, pageSize, sort, search },
    });
    return { data };
  } catch (error) {
    return { error };
  }
}

/** get businesses by building id */
export async function getBuildingStores(id) {
  try {
    const token = await localStorage.getItem("token");
    const data = await axios.get(`/business/businesses/${id}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return data;
  } catch (error) {
    return { error: "Stores not found" };
  }
}

/** get businesses */
export async function getAllBusinesses() {
  try {
    const token = await localStorage.getItem("token");
    const data = await axios.get("/business/businesses", {
      headers: { Authorization: `Bearer ${token}` },
    });
    return data;
  } catch (error) {
    return { error: "Buildings not found" };
  }
}

/** update business function */
export async function updateBusiness(_id) {
  try {
    const token = await localStorage.getItem("token");
    const data = await axios.put("/business/update", _id, {
      headers: { Authorization: `Bearer ${token}` },
    });

    return Promise.resolve({ data });
  } catch (error) {
    return Promise.reject({ error: "Couldn't Update Building...!" });
  }
}
/**Get county */
export async function getCounty(code) {
  try {
    const token = await localStorage.getItem("token");
    const county = await axios.get(`/county/counties/${code}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return county;
  } catch (error) {
    return { error: "County not found" };
  }
}

/**Get county buildings */
export async function getCountyBuildings({ county, category }) {
  try {
    const token = localStorage.getItem("token");
    const headers = { Authorization: `Bearer ${token}` };
    const params = category !== "" ? { category } : {};

    const buildings = await axios.get(`/buildings/by-county/${county}`, {
      params,
      headers,
    });

    return buildings;
  } catch (error) {
    return { error: "County not found" };
  }
}

/**GET ESCALATIONS */
export async function getEscalations() {
  try {
    const token = await localStorage.getItem("token");
    const escalations = await axios.get("/business/escalations", {
      headers: { Authorization: `Bearer ${token}` },
    });
    return escalations;
  } catch (error) {
    return { error };
  }
}

/**GET DAILY TRANSACTIONS */
export async function getDaily({ days }) {
  try {
    const token = await localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    const res = await axios.get(
      `/transactions/daily`,
      days !== "" && {
        params: { days },
        headers,
      }
    );
    return res;
  } catch (error) {
    return error;
  }
}

/**GET DAILY TRANSACTIONS */
export async function getMonthly({ months }) {
  try {
    const token = await localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    const res = await axios.get(
      `/transactions/monthly`,
      months !== "" && {
        params: { months },
        headers,
      }
    );
    return res;
  } catch (error) {
    return error;
  }
}

/**GET ALL WARD SUMMARIES */
export async function getCountyWardSummaries() {
  try {
    const token = await localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    const res = await axios.get(`/county/summaries`);
    return res;
  } catch (error) {
    return error;
  }
}

/**Send Message */
export async function sendMail({ to, from, name, email_body }) {
  try {
    const token = await localStorage.getItem("token");
    const res = await axios.post(`/user/send-mail/`, {
      to,
      from,
      name,
      email_body,
      headers: { Authorization: `Bearer ${token}` },
    });
    return res;
  } catch (error) {
    return { error: "message not sent" };
  }
}

export function renameObjectKeys(array, keyMap) {
  const newArray = [];

  for (let i = 0; i < array.length; i++) {
    const oldObject = array[i];
    const newObject = {};

    for (let oldKey in oldObject) {
      if (oldObject.hasOwnProperty(oldKey)) {
        const newKey = keyMap[oldKey] || oldKey;
        newObject[newKey] = oldObject[oldKey];
      }
    }
    newArray.push(newObject);
  }

  return newArray;
}

export function getPaymentStatusDistribution(permits) {
  const result = permits?.reduce(
    (acc, permit) => {
      const paymentStatus = permit.payment_status;

      if (paymentStatus === "Paid") {
        acc.paid++;
      } else if (paymentStatus === "Partially Paid") {
        acc.partiallyPaid++;
      } else if (paymentStatus === "Not Paid") {
        acc.notPaid++;
      }

      return acc;
    },
    { paid: 0, partiallyPaid: 0, notPaid: 0 }
  );

  if (permits.length === 0) {
    return "No Occupants";
  }

  const highestPaymentStatus = Object.keys(result).reduce((a, b) =>
    result[a] > result[b] ? a : b
  );

  return highestPaymentStatus;
}

export function calculateMapCenter(objects) {
  let totalLatitude = 0;
  let totalLongitude = 0;

  for (const obj of objects) {
    const latitude = parseFloat(obj.latitude);
    const longitude = parseFloat(obj.longitude);
    totalLatitude += latitude;
    totalLongitude += longitude;
  }
  const centerLatitude = totalLatitude / objects.length;
  const centerLongitude = totalLongitude / objects.length;

  return { latitude: centerLatitude, longitude: centerLongitude };
}

export function clusterCoordinates(coordinates, threshold) {
  const clusters = [];

  // Iterate over each coordinate
  for (let i = 0; i < coordinates.length; i++) {
    const coordinate = coordinates[i];

    // Check if the coordinate belongs to any existing cluster
    let foundCluster = false;
    for (let j = 0; j < clusters.length; j++) {
      const cluster = clusters[j];

      // Calculate the distance between the current coordinate and the cluster's center
      const distance = Math.sqrt(
        Math.pow(coordinate.latitude - cluster.center.latitude, 2) +
          Math.pow(coordinate.longitude - cluster.center.longitude, 2)
      );

      // If the distance is below the threshold, add the coordinate to the cluster
      if (distance < threshold) {
        cluster.coordinates.push(coordinate);
        foundCluster = true;
        break;
      }
    }

    // If the coordinate doesn't belong to any cluster, create a new cluster with the coordinate as the center
    if (!foundCluster) {
      clusters.push({
        center: coordinate,
        coordinates: [coordinate],
      });
    }
  }

  return clusters;
}
